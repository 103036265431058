header{
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
}

.header__container{
    text-align: center;
    height: 100%;
    position: relative;
}

/* ============================== CTA ==================================*/
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ============================== SOCIALS ==================================*/
.header__socials{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after{
    content: '';
    width: 1px;
    height: 2rem;
    background-color: var(--color-primary);
}

/* ============================== ME ==================================*/

.me{
    background-color: linear-gradient(var(--color-primary), transparent);
    width: 30rem;
    height: 35rem;
    position: absolute;
    left: calc(50% - 14rem);
    margin-top: 4rem;
    overflow: hidden;
    padding: 3rem 1.5rem 1.5rem 1.5rem;
}

/* ============================== SCROLL DOWN ==================================*/
.scroll__down{
    text-decoration: none;
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ================================ MEDIA QUERIES (MEDIUM DEVICES) ================================ */
@media screen and (max-height: 2160),(max-width:3840px) {
    .header__socials{
        bottom: 26rem;
    }
    .scroll__down {
        bottom: 28rem;
    }
}

@media screen and (max-width:1024px) {
    .header{
        height: 68vh;
    }
}

/* ================================ MEDIA QUERIES (SMALL DEVICES) ================================ */
@media screen and (max-width:600px) {
    .header {
        height: 100vh;
    }

    .header__socials,
    .scroll__down {
        display: none;
    }
}