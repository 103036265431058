.container.coursework__container {
    width: 40%;

}

.course__grade {
    text-align: center;
}

.course {
    background-color: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}

.course__description {
    color: var(--color-light);
    font-weight: 300;
    display: block;
    width: 80%;
    margin: 0.8rem auto 0;
    padding-bottom: 0.5rem;
}

/* ================================ MEDIA QUERIES (MEDIUM DEVICES) ================================ */
@media screen and (max-width:1024px) {
    .container.coursework__container {
        width: 60%;
    }
}

/* ================================ MEDIA QUERIES (SMALL DEVICES) ================================ */
@media screen and (max-width:600px) { 
    .container.coursework__container {
        width: var(--container-width-sm);
    }

    .course__description {
        width: var(--container-width-sm);
    }
}