nav{
    background: rgba(0,0,0,0.3);
    width: max-content;
    display: block;
    padding: 0.7rem 1.7rem;
    z-index: 2;
    position: fixed;
    left: 48%;
    transform: translateX(-50%);
    rotate: -90deg;
    bottom: 2rem;
    gap: 0.8rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
}

nav a {
    rotate: 90deg;
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
}

nav a:hover {
    background: rgba(0,0,0,0.3)
}

nav a.active {
    background: var(--color-primary);
    color: var(--color-bg);
}

/* ================================ MEDIA QUERIES (MEDIUM DEVICES) ================================ */
@media screen and (max-width:1024px) {
    nav {
        left: 43%;
    }
}

/* ================================ MEDIA QUERIES (SMALL DEVICES) ================================ */
@media screen and (max-width:600px) { 
    nav {
        left: 36%;
    }
}